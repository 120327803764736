body {
  background-color: #383d47;
}

.App {
  text-align: center;
}

.Header-logo {
  height: calc(12px + 3vmin);
  padding-left: 1vw;
}

.Group-logo {
  height: auto;
  width: 100%;
}

.Group-image{
  height: auto;
  width: 100%;
}

.Group-container {
  display: flex;
  flex-direction: column;
  padding: 1vmin;
}
.Container_img {
  height: auto;
  width: 100%;
}
.showtimes {
  min-height: 4vh
}

.Group-container-item {
  height: 100%;
  width: auto;
  padding: 1vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0.25vh;
  margin-bottom: 1vh;
}

a:link {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration: none;
  color: white;
}
*{
    padding: 0px;
    margin: 0px;
}

h2{
    color: white;
    text-align: center;
}

h3{
    color: white;
}

.text_right{
    text-align: right;
}

p{
    color: white;
}

.Text_Light{
    color: white;
}
.Logo {
    width: 100%;
    height: auto;
}

.Fit_image{
    height: auto;
    width: 100%;
}

.GroupPage_Content_Row{
    margin-top: 20px;
    margin-bottom: 20px;
    flex-flow: row wrap;
}

.GroupPage_Content_Row_Flex_Reverse{
    margin-top: 20px;
    margin-bottom: 20px;
    flex-flow: row wrap-reverse;
    padding: 20px;
}

.Banner{
    max-width: 100%;
    height: auto
}

.Video_Embed {
    position: relative; 
    padding-bottom: 56.25%; 
    max-width: 100%;
    height: 0; 
    overflow: hidden; 
}

.Video_Embed_Small {
    position: relative; 
    padding-bottom: 20%; 
    max-width: 100%;
    height: 0; 
    overflow: hidden; 
}
.Video_Embed_Wide {
    position: relative; 
    padding-bottom: 40%; 
    max-width: 100%;
    height: 0; 
    overflow: hidden; 
}

.Video_Embed_iFrame{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
}

#GroupPageLogo{
    min-height: 50vmin;
    padding: 20px
}

.Spotify_Embed{
    padding: 10px;
}

/* Style all font awesome icons (Social Media)*/
.fa {
    padding: 20px;
    font-size: 10px;
    width: 50px;
    text-align: center;
    text-decoration: none;
  }